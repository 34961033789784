.editor-layout {
    width: 80%;
    height: 100px;
    border-style: solid;
    border-width: 2px;
    border-color: blue;
    margin-left: 20px;
    padding-bottom: 40px;
  }

  .parentDiv{
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}
.childDiv{
    position: absolute;
    top: 0;
    left: 0;
    bottom: -20px;
    right: -20px; 
    overflow: scroll;
 }

 /* Tell Quill not to scroll */
#quill-container {
  height: auto;
  min-height: 100%;
  padding: 50px;
}
#quill-container .ql-editor {
  font-size: 18px;
  overflow-y: visible; 
}

/* Specify our own scrolling container */
#scrolling-container {
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
}
 

  